import "./App.css";
import React, { useState, useEffect } from "react";
import LocalizedStrings from 'react-localization';

import en_flag from './media/en.png';
import es_flag from './media/es.png';
import scan from './media/scan.png';
import play from './media/play.png';
import logoPrincipal from './media/logo.png';
import ambientAudio from './media/ambient.m4a';
import voiceover_en from './media/vo_en.m4a';
import voiceover_es from './media/vo_es.m4a';
import vspt_es from './media/vspt_es.mp4';
import vspt_en from './media/vspt_en.mp4';
import condorgif from './media/condor.gif';

import { Button, createMuiTheme, makeStyles, Paper, ThemeProvider, Typography } from "@material-ui/core";

const localization = new LocalizedStrings({
	en:{
		language: 'Language',
		scan: 'Scan the VSPT logo.',
		tap: 'Play'
	},
	es:{
		language: 'Idioma',
		scan: 'Escanea el logo de VSPT.',
		tap: 'Play'
	}
});

const theme = createMuiTheme({
	palette:{
		primary:{
			main: '#301234'
		},
		secondary:{
			main: '#E1D3B7'
		}
	},
	extra:{
		beige: '#E1D3B7',
		red: '#CC183D'
	}
});

const useStyles = makeStyles({
	container:{
		position: 'absolute',
		zIndex: 1000,
		width: '100vw',
		height: '100vh',
		left: 0, 
		top: 0,
		display: 'flex',
		flexDirection: 'column',
		alignContent: 'center'
	},
	mainPanel:{
		padding: '3vw',
		marginRight: '15vw',
		marginLeft: '15vw',
		marginTop: '1vw',
		marginBottom: '1vh',
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: theme.palette.primary.main,
		opacity: 1,
	},
	mainPanelHidden:{
		padding: '3vw',
		marginRight: '15vw',
		marginLeft: '15vw',
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: theme.palette.primary.main,
		opacity: 0,
		transition: 'opacity 0.5s',
	},
	flag:{
		width: 'auto',
		height: '30px',
		alignSelf: 'center',
		pointerEvents: 'none'
	},
	logo:{
		maxHeight: '45vw',
		width: 'auto',
		alignSelf: 'center',
		pointerEvents: 'none',
		display: 'block',
		margin: 0
	},
	scan: {
		width: '75vw',
		height: 'auto',
		alignSelf: 'center',
		pointerEvents: 'none'
	},
	scanText: {
		color: theme.extra.red,
		textTransform: 'none',
		textAlign: 'center',
		fontFamily: 'Montserrat-Bold',
		fontSize: '16pt',
		alignSelf: 'center',
		marginTop: '2vh'
	},
	logoEnd:{
		width: '60vw',
		height: 'auto',
		alignSelf: 'center',
		pointerEvents: 'none',
		border: '0',
		opacity: 1,
		transition: 'opacity 1s',
		overflow: 'hidden'
	},
	logoEndHidden:{
		width: '60vw',
		height: 'auto',
		alignSelf: 'center',
		pointerEvents: 'none',
		border: '0',
		opacity: 0,
		overflow: 'hidden'
	},
	langButton:{
		margin: '5px',
		marginRight: '15px',
		marginLeft: '15px',
		borderRadius: "5em",
		minHeight: '6.5vh',
	},
	langButtonText:{
		color: theme.palette.primary.main,
		textTransform: 'none',
		fontFamily: 'Montserrat-Bold'
	},
	finishPanelHidden:{
		opacity: 0,
	},
	finishPanel:{
		backgroundColor: theme.palette.primary.main,
		position: 'absolute',
		zIndex: 1000,
		opacity: 1,
		width: '100vw',
		height: '100vh',
		top: 0,
		left: 0,
		transition: 'opacity 1s',
		display: 'flex',
		flexDirection: 'column',
	},
	videoPanelHidden:{
		opacity: 0
	},
	videoPanel:{
		backgroundColor: theme.palette.primary.main,
		position: 'absolute',
		zIndex: 1000,
		opacity: 1,
		width: '100vw',
		height: '100vh',
		top: 0,
		left: 0,
		transition: 'opacity 1s',
		display: 'flex',
		flexDirection: 'column',
	},
	videoForeground:{
		position: 'absolute',
		zIndex: 1200,
		opacity: 1,
		width: '100vw',
		height: '100vh',
		top: 0,
		left: 0,
		transition: 'opacity 1s',
		display: 'flex',
		flexDirection: 'column',
	},
	videoForegroundHidden:{
		opacity: 0
	},
	videoCondor: {
		width: '100vw',
		top: 0,
		left: 0,
		position: 'absolute'
	},
	condor:{
		position: 'absolute',
		left: '0',
		top: '-10vh',
		pointerEvents: 'none',
		width: '100vw',
		height: 'auto',
	},
	videoButton:{
		margin: '5px',
		borderRadius: "5em",
		minHeight: '6.5vh',
		alignSelf: 'center',
		opacity: 1,
		transition: 'opacity 1s',
	},
	videoButtonHidden:{
		margin: '5px',
		borderRadius: "5em",
		minHeight: '6.5vh',
		alignSelf: 'center',
		opacity: 0
	},
	videoButtonText:{
		color: theme.palette.primary.main,
		textTransform: 'none',
		fontFamily: 'Montserrat-Bold',
		fontSize: '20pt'
	},
	playIcon:{
		maxHeight: '3vh',
		width: 'auto',
		marginLeft: '2vw',
	},
	hidden:{
		opacity: 0
	}
});

export default function App(){
	const [ambient] = useState(new Audio());
	const [voiceover] = useState(new Audio());
	let voAudio = voiceover_es;
	let videoVar = vspt_es;
	const [loaded,setLoaded] = useState(false);
	const [detecting,setDetecting] = useState(false);
	const [detected,setDetected] = useState(false);
	const [finished,setFinished] = useState(false);
	const [video,setVideo] = useState(vspt_es);
	const [videoPlaying,setPlaying] = useState(false);
	const [videoVisible,setVideoVisible] = useState(false);
	const [finished2,setFinished2] = useState(false);
	const classes = useStyles();
	let playing = false;
	let completed = false;
	
	useEffect(() => {
		// eslint-disable-next-line
		document.oncontextmenu = new Function("return false;");
		ambient.loop = true;
		ambient.volume = 0.8;
		ambient.play();
		voiceover.loop = false;
		voiceover.volume = 1;
		voiceover.play();
		setLang("en");
	});

	const getMarker = async() => {
		setDetected(true);
		playAudio();
		setVideo(videoVar);
	}

	const setMarker = () => {
		window.addEventListener("markerFound", (_marker) => {
			getMarker();
		});
	}

	const playAudio = () => {
		if(!playing && !completed){
			playing = true;
			ambient.src = ambientAudio;
			ambient.play();
			setTimeout(playVoiceover, 1000);
		}
	}

	const playVoiceover = () => {
		voiceover.src = voAudio;
		voiceover.play();
	}

	const setLang = (_lang) => {
		if(!localization.getAvailableLanguages().includes(_lang))
			return;
		localization.setLanguage(_lang);
		setLoaded(true);
		switch(_lang){
			default:
			case 'es':{
				voAudio = voiceover_es;
				videoVar = vspt_es;
				//setVideo(vspt_es);
				break;
			}
			case 'en':{
				voAudio = voiceover_en;
				videoVar = vspt_en;
				break;
			}
		}
	}

	const setLangBtn = (_lang) => {
		setLang(_lang);
		setDetecting(true);
		ambient.play();
		voiceover.play();
		setMarker();
	}

	const flagIcon = (_src) => {
		return <img className = {classes.flag} src={_src} alt ="flag"/>
	}

	function LangButton(props){
		const {onClick, icon} = props;

		return(
			<Button className = {classes.langButton} variant = 'contained' color = 'secondary' onClick = {onClick} startIcon = {flagIcon(icon)}>
				<Typography className = {classes.langButtonText}>
					{props.children}
				</Typography>
			</Button>
		);
	}

	function VideoButton(props){
		const {onClick} = props;

		return(
			<Button className = {videoVisible ? classes.videoButtonHidden : classes.videoButton} variant = 'contained' color = 'secondary' onClick = {onClick} >
				<Typography className = {classes.videoButtonText}>
					{props.children}
				</Typography>
				<img className = {classes.playIcon} alt = "play" src = {play} />
			</Button>
		);
	}

	const finishAnim = () => {
		if(!completed){
			ambient.pause();
			ambient.muted = true;
			voiceover.muted = true;
			completed = true;
			setFinished(true);
			setTimeout(completeAnim,400);
		}
	}

	const completeAnim = () => {
		setDetected(false);
		setFinished2(true)
	}

	const click = (_id) => {
		if(!videoPlaying){
			document.getElementById(_id).play();
			setVideoVisible(true);
		}
	}

	return(
		<ThemeProvider theme = {theme} className="App">
			{loaded ? 
				<div className = {classes.container}>
					<div style = {{flex: 1}} />
						<Paper className = {!detecting ? classes.mainPanel : classes.mainPanelHidden}>
							<img className = {classes.logo} src = {logoPrincipal} alt = "logo"/>
							 
							<LangButton onClick = {() => setLangBtn('es')} icon = {es_flag} alt = "español">
								Español
							</LangButton>
							<LangButton onClick = {() => setLangBtn('en')} icon = {en_flag} alt = "english">
								English
							</LangButton>
							
							{/* 
							<Button className = {classes.langButton} variant = 'contained' color = 'secondary' onClick = {() => setLangBtn('en')}>
								<Typography className = {classes.langButtonText}>
									Start
								</Typography>
							</Button>
							*/}
						</Paper>
					<div style = {{flex: 1}} />
				</div>
			: null}
			{detecting && !detected && !finished ? 
				<div style={{ height: '100vh', width: '100vw', top: 0, left: 0, position: 'absolute', display: 'flex', flexDirection: 'column' }}>
					<div style={{flex: 0.4}}/>
					<img className={classes.scan} src={scan} alt="scan" />
					<Typography className = {classes.scanText}>{localization.scan}</Typography>
				</div>
			: null}
			{detected && !finished2?
				<div style = {{height: '100vh', width: '100vw', top: 0, left: 0, position: 'absolute', display: 'flex'}}>
					<img alt = "condor" src = {condorgif} className = {classes.condor} onLoad = {() => {setTimeout(finishAnim,9500);}} />
				</div>
			: null}
			<div className = {!finished ? classes.videoPanelHidden : classes.videoPanel}>
				<div style = {{flex: 1}} />
				<img className = {videoVisible ? classes.logoEndHidden : classes.logoEnd} src = {logoPrincipal} alt = "logo" />
				<VideoButton onClick = {() => click("vspt")} alt = "playVideo">
					{localization.tap}
				</VideoButton>
				<div style = {{flex: 1}} />
			</div>
			<div className = {!videoVisible ? classes.videoForegroundHidden : classes.videoForeground}>
				<div style = {{flex: 0.7}} />
				<video id = "vspt" autoPlay = {videoPlaying} playsInline = {true} src = {video} style = {{alignSelf: 'center', pointerEvents: 'none', width: '100vw'}} onPlay = {() => {setPlaying(true)}} onLoad = {() => click("vspt")} />
				<div style = {{flex: 1}} />
			</div>
		</ThemeProvider>
	);
};
